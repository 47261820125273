import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Accepted MIPs`}</h1>
    <p>{`Maker Improvement Proposals work together in sets, which we call a governance paradigm. This page presents the current governance paradigm our community uses in the pursuit of building better money, as well as linking to the full text of each accepted MIP.`}</p>
    <h2>{`Active MIP Sets`}</h2>
    <h3>{`Core Governance Framework`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`MIP №`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Date Ratified`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Proposal Link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description/Title`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP1"
            }}>{`MIP1`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maker Governance Paradigms`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP2"
            }}>{`MIP2`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Launch Period`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP3"
            }}>{`MIP3`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Governance Cycle`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP4"
            }}>{`MIP4`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MIP Amendment and Removal Process`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP5"
            }}>{`MIP5`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Emergency Voting System`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Collateral Onboarding Framework`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`MIP №`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Date Ratified`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Proposal Link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description/Title`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP6"
            }}>{`MIP6`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Collateral Onboarding Form/Forum Template`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP7"
            }}>{`MIP7`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain Teams (Collateral Onboarding)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP8"
            }}>{`MIP8`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain Greenlight (Collateral Onboarding)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP9"
            }}>{`MIP9`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Community Greenlight (Collateral Onboarding)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP10"
            }}>{`MIP10`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Oracle Management`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP11"
            }}>{`MIP11`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General Risk Model Management (Collateral Onboarding)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/tree/master/MIP12"
            }}>{`MIP12`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Collateral and Risk Parameter Management`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2020-05-14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/makerdao/mips/blob/master/MIP13"
            }}>{`MIP13`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Declarations of Intent`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      